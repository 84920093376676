import React from "react";
import Nav from "../../components/navbar/nav";
import Footer from "../../components/navbar/footer";
export default function PrivacyPolicy() {
  return (
    <React.Fragment>
      <Nav />
      <section>
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-md-7">
              <h1 className="title mb-4">Privacy Policy</h1>
              <p className='mb-3'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Maecenas varius tortor nibh, sit amet tempor nibh finibus et.
                Aenean eu enim justo. Vestibulum aliquam hendrerit molestie.
                Mauris malesuada nisi sit amet augue accumsan tincidunt.
                Maecenas tincidunt, velit ac porttitor pulvinar, tortor eros
                facilisis libero, vitae commodo nunc quam et ligula. Ut nec
                ipsum sapien. Interdum et malesuada fames ac ante ipsum primis
                in faucibus. Integer id nisi nec nulla luctus lacinia non eu
                turpis. Etiam in ex imperdiet justo tincidunt egestas. Ut
                porttitor urna ac augue cursus tincidunt sit amet sed orci.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Maecenas varius tortor nibh, sit amet tempor nibh finibus et.
                Aenean eu enim justo. Vestibulum aliquam hendrerit molestie.
                Mauris malesuada nisi sit amet augue accumsan tincidunt.
                Maecenas tincidunt, velit ac porttitor pulvinar, tortor eros
                facilisis libero, vitae commodo nunc quam et ligula. Ut nec
                ipsum sapien. Interdum et malesuada fames ac ante ipsum primis
                in faucibus. Integer id nisi nec nulla luctus lacinia non eu
                turpis. Etiam in ex imperdiet justo tincidunt egestas. Ut
                porttitor urna ac augue cursus tincidunt sit amet sed orci.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
}
