import React from "react";
import Nav from "../../components/navbar/nav";
import PageNotFound from "../../images/page-not-found.svg";
export default function NoMatch() {
  return (
    <div>
      <Nav />
      <section>
          <div className='container'>
              <div className='row'>
                  <div className='col-md-12'>
                  <img src={PageNotFound} className='img-fluid' alt="pagenotfound" />
                  </div>
              </div>
          </div>
      </section>
     
    </div>
  );
}
