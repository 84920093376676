import React, { Component } from "react";

import Card from "../card/card";
import BottomRowStep from "../common/bottomRowStep";
export default class AddApplicants extends Component {
  render() {
    return (
      <Card
        title="Add more Applicants"
        subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing"
      >
        <div class="form-check">
          <label class="form-check-label" for="exampleRadios1">
            <input
              class="form-check-input"
              type="radio"
              name="exampleRadios"
              id="exampleRadios1"
              value=""
            />
            Yes
          </label>
        </div>
        <div class="form-check">
          <label class="form-check-label" for="exampleRadios2">
            <input
              class="form-check-input"
              type="radio"
              name="exampleRadios"
              id="exampleRadios2"
              value=""
            />
            no
          </label>
        </div>
        <BottomRowStep skip={this.props.skip} next={this.props.next} />
      </Card>
    );
  }
}
