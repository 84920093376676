import React, { Component } from "react";


import Card from "../card/card";
import BottomRowStep from "../common/bottomRowStep";

class BasicInfoOne extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
 
  render() {
    return (
      <Card title='Basic Information' subtitle='Lorem ipsum dolor sit amet, consectetur adipiscing'>
       
          <form className='row custom-form'>
            <label className="col-md-12">Date of birth</label>
            <div class="form-group col-md-3">
              <select class="form-control custom-select-control">
                <option disabled selected>Month</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div>
            <div class="form-group col-md-2">
              <select class="form-control custom-select-control">
                <option disabled selected>Day</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div>
            <div class="form-group col-md-2">
              <select class="form-control custom-select-control">
                <option disabled selected>Year</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div>
          </form>
          

          <BottomRowStep skip={this.props.skip} next={this.props.next}/>
     
      </Card>
    );
  }
}

export default BasicInfoOne;
