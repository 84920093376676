import React from "react";

import facebook from "../../images/facebook.svg";
import mail from "../../images/mail.svg";
import linkedin from "../../images/linkedin.svg";
import twitter from "../../images/twitter.svg";
export default function footer() {
  return (
    <footer className="footer-nav">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <label>Write us at</label>
            <p>getquickpassport@gmail.com</p>
            <p>+91 8652316581 / 02227654321</p>
          </div>
          <div className="col-md-3">
            <label>links</label>
            <ul className="list-unstyled">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/#services">Services</a>
              </li>
              <li>
                <a href="/contact-us">Contact Us</a>
              </li>
              <li>
                <a href="/about-us">About Us</a>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <label>Legal</label>
            <ul className="list-unstyled">
              <li>
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
              <li>
                <a href="terms-and-conditions">Terms & Conditions</a>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <div className="title">QuickPassport </div>
            <div className=" social-share">
              <a>
                <img src={facebook} />
              </a>
              <a>
                <img src={twitter} />
              </a>
              <a>
                <img src={linkedin} />
              </a>
              <a>
                <img src={mail} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        Copyright Quickpassport.in 2019. All rights reserved
      </div>
    </footer>
  );
}
