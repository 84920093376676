import React, { Component } from "react";
import SlickSlider from "react-slick";
import LeftArrow from "../../images/left-arrow.svg";
import RightArrow from "../../images/right-arrow.svg";
export default class Testimonials extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSlide: 1
    };
  }

  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      className: "testimonials-slider",
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      afterChange: current => this.setState({ activeSlide: current + 1 })
    };
    return (
      <section className="testimonials">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h5>Our Customers love what we do</h5>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>
            <div className="col-md-6">
              <SlickSlider {...settings}>
                <div>
                  <div className="item">
                    <div className="inner-card">
                      <div className="testi-icon">“</div>
                      <div className="message">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Maecenas varius tortor nibh
                      </div>
                      <div className="name">Matthew Parsons</div>
                    </div>
                    <div className="inner-card">
                      <div className="testi-icon">“</div>
                      <div className="message">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Maecenas varius tortor nibh
                      </div>
                      <div className="name">Matthew Parsons</div>
                    </div>
                    <div className="inner-card">
                      <div className="testi-icon">“</div>
                      <div className="message">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Maecenas varius tortor nibh
                      </div>
                      <div className="name">Matthew Parsons</div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="item">
                    <div className="inner-card">
                      <div className="testi-icon">“</div>
                      <div className="message">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Maecenas varius tortor nibh
                      </div>
                      <div className="name">Matthew Parsons</div>
                    </div>
                    <div className="inner-card">
                      <div className="testi-icon">“</div>
                      <div className="message">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Maecenas varius tortor nibh
                      </div>
                      <div className="name">Matthew Parsons</div>
                    </div>
                    <div className="inner-card">
                      <div className="testi-icon">“</div>
                      <div className="message">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Maecenas varius tortor nibh
                      </div>
                      <div className="name">Matthew Parsons</div>
                    </div>
                  </div>
                </div>
              </SlickSlider>
              <div className="slide-count">{this.state.activeSlide}/2</div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      src={LeftArrow}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      src={RightArrow}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}
