import React, { Component } from "react";

import MenuButton from "../../images/bx-menu.svg";
import Close from "../../images/noun-close-1809937.svg";

class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navDisplay: false
    };
  }
  toogleNav = () => {
    this.setState({
      navDisplay: !this.state.navDisplay
    });
  };

  render() {
    const { navDisplay } = this.state;
    return (
      <React.Fragment>
        <nav class="navbar navbar-expand-lg custom-navbar">
          <div class="container">
            <a class="navbar-brand" href="/">
              QuickPassport
            </a>
            <button
              class="navbar-toggler"
              type="button"
              // data-toggle="collapse"
              // data-target="#navbar-primary"
              // aria-expanded="false"
              // aria-label="Toggle navigation"
              onClick={this.toogleNav}
            >
              <span class="navbar-toggler-icon">
                <img src={MenuButton} />
              </span>
            </button>

            <div class="collapse navbar-collapse" id="navbar-primary">
              <ul class="navbar-nav ml-auto">
                <li class="nav-item active">
                  <a class="nav-link" href="/">
                    Home <span class="sr-only">(current)</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link disabled" href="/about-us">
                    About us
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/#services">
                    Services
                  </a>
                </li>

                <li class="nav-item">
                  <a class="nav-link" href="/contact-us">
                    Contact
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link">
                    Login
                  </a>
                </li>
                <li class="nav-item ">
                  <a className="primary-button nav-link" href='/fresh-passport'>Get started</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {navDisplay && (
          <div className="mobile-nav">
            <div class="container h-100">
              <div class="row align-items-center h-100">
                <div class="col-6 mx-auto">
                  <ul>
                    <li>
                      <a href='/'>Home</a>
                    </li>
                    <li>
                      <a  href="/about-us">About us</a>
                    </li>
                    <li>
                      <a href="/#services">Services</a>
                    </li>
                    <li>
                      <a href='/'>Login</a>
                    </li>
                    <li>
                      <a href="/contact-us">Contact us</a>
                    </li>
                    <li className="close-nav" onClick={this.toogleNav}>
                      <img src={Close} />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default NavBar;
