import React from "react";
import Nav from "../../components/navbar/nav";
import ContactUsComp from "../../components/pages/contactUs";
import Footer from "../../components/navbar/footer";
export default function ContactUs() {
  return (
    <>
      <Nav />
      <ContactUsComp />
      <Footer />
    </>
  );
}
