import React, { Component } from "react";
import SlickSlider from "react-slick";

// images
import SliderIllu1 from "../../images/group-copy.svg";
import LeftArrow from "../../images/left-arrow.svg";
import RightArrow from "../../images/right-arrow.svg";
export default class Slider extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      className: "hero-slider",
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows:false
          }
        }
      ]
    };
    return (
      <div className="container">
        <SlickSlider {...settings}>
          <div>
            <div class="item">
              <div className="text-content">
                <h2>
                  Now getting a passport is simple and easy with Quick Passport
                </h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Maecenas varius tortor nibh,
                </p>
                <button>Get started</button>
              </div>
              <div className="image-content">
                <img src={SliderIllu1} className="img-fluid" />
              </div>
            </div>
          </div>
          <div>
            <div class="item">
              <div className="text-content">
                <h2>
                  Now getting a passport is simple and easy with Quick Passport
                </h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Maecenas varius tortor nibh,
                </p>
                <button>Get started</button>
              </div>
              <div className="image-content">
                <img src={SliderIllu1} className="img-fluid" />
              </div>
            </div>
          </div>
          <div>
            <div class="item">
              <div className="text-content">
                <h2>
                  Now getting a passport is simple and easy with Quick Passport
                </h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Maecenas varius tortor nibh,
                </p>
                <button>Get started</button>
              </div>
              <div className="image-content">
                <img src={SliderIllu1} className="img-fluid" />
              </div>
            </div>
          </div>
        </SlickSlider>
      </div>
    );
  }
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      src={LeftArrow}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      src={RightArrow}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}
