import React from "react";
import { Link } from "react-router-dom";
import ServiceImg from "../../images/service.svg";
export default function ServiceCard() {
  return (
    <section className=" service-card" id="services">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <Link to="">
              <div className="card-block ">
                <img src={ServiceImg} />
                <h3>Fresh Passport</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                <Link to="/">apply now</Link>
              </div>
            </Link>
          </div>
          <div className="col-md-3">
            <Link to="">
              <div className="card-block ">
                <img src={ServiceImg} />
                <h3>Fresh Passport</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                <Link to="/">apply now</Link>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
