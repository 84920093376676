import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

// Fonts

// custom style
import "./style/style.scss";
// Import FilePond styles
import "./style/filepond.min.css"
import "./style/filepond-plugin-image-preview.css";


// importing the pages
import Home from "./container/pages/index";
import AboutUs from "./container/pages/aboutUs";
import ContactUs from "./container/pages/contactUs";
import TermsNCond from "./container/pages/termsAndCondition";
import PrivacyPolicy from "./container/pages/privacyPolicy";

import SignUp from "./container/auth/signUp";
import SignIn from './container/auth/signIn'

import FreshPassport from "./container/service_page/freashPassport";



import NoMatch from "./container/miscellaneous/noMatch";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* Navigation  */}
      <Route exact path="/" component={Home} />
      <Route exact path="/about-us" component={AboutUs} />
      <Route exact path="/contact-us" component={ContactUs} />
      <Route exact path="/terms-and-conditions" component={TermsNCond} />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />

      {/* Auth */}
      <Route exact path="/sign-up" component={SignUp} />
      <Route exact path="/sign-in" component={SignIn} />

      {/* application flow */}
      <Route exact path="/fresh-passport" component={FreshPassport} />
     
   

      <Route component={NoMatch} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
