import React, { Component } from "react";
import Nav from "../../components/navbar/nav";
import Card from "../../components/card/smallCard";
import SignUpComp from "../../components/auth/signUpComp";

export default class SignUp extends Component {
  render() {
    return (
      <React.Fragment>
        <Nav />
        <section>
          <Card
            title="Sign Up"
            subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit.  "
          >
            <SignUpComp />
          </Card>
        </section>
      </React.Fragment>
    );
  }
}
