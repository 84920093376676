import React, { Component } from "react";
import Card from "../card/card";
import BottomRowStep from "../common/bottomRowStep";
export default class residenceInfo extends Component {
  render() {
    return (
        <Card title='Residence Informationn' subtitle='Lorem ipsum dolor sit amet, consectetur adipiscing'>
       
        <form className='row custom-form'>
          <label className="col-md-12">Select an option</label>
          <div class="form-group col-md-12">
            <select class="form-control custom-select-control">
              <option disabled selected>Rented</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </div>
        
        </form>
        

        <BottomRowStep skip={this.props.skip} next={this.props.next}/>
   
    </Card>
    );
  }
}
