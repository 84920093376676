import React, { Component } from "react";

import BasicDetailOne from "../service_comp/basicInfo";
import BasicDetailTwo from "../service_comp/basicInfoTwo";
import Adhar from "../service_comp/adharUpload";
import PanCard from "../service_comp/panCardUpload";
import ResidenceInfo from "../service_comp/residenceInfo";
import MinorUpload from "../service_comp/minor";
import MajorUpload from '../service_comp/major';
import Booklet from '../service_comp/booklet';
import AddApplicants from '../service_comp/addApplicants';

export default class FreshPassportComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1
    };
  }

  next = () => {
    this.setState({
      step: ++this.state.step
    });
  };
  skip = () => {};
  render() {
    const { step } = this.state;
    const currentComp = [];
    if (step === 1) {
      currentComp.push(<BasicDetailOne skip={this.skip} next={this.next} />);
    } else if (step === 2) {
      currentComp.push(<BasicDetailTwo skip={this.skip} next={this.next} />);
    } else if (step === 3) {
      currentComp.push(<Adhar skip={this.skip} next={this.next} />);
    } else if (step === 4) {
      currentComp.push(<PanCard skip={this.skip} next={this.next} />);
    } else if (step === 5) {
      currentComp.push(<ResidenceInfo skip={this.skip} next={this.next} />);
    } else if (step === 6) {
      currentComp.push(<MinorUpload skip={this.skip} next={this.next} />);
    }else if (step === 7) {
      currentComp.push(<MajorUpload skip={this.skip} next={this.next} />);
    }else if (step === 8) {
      currentComp.push(<Booklet skip={this.skip} next={this.next} />);
    }else if (step === 9) {
      currentComp.push(<AddApplicants skip={this.skip} next={this.next} />);
    }
    return <React.Fragment>{currentComp}</React.Fragment>;
  }
}
