import React from "react";

export default function Wrapper(props) {
  return (
    <div className="wrapper">
      <h1>{props.heading}</h1>
      <h2>{props.subheading}</h2>
      {props.children}
    </div>
  );
}
