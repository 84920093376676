import React, { Component } from "react";
import Card from "../card/card";
import BottomRowStep from "../common/bottomRowStep";
export default class BasicInfo2 extends Component {
  render() {
    return (
      <Card title='Basic Information' subtitle='Lorem ipsum dolor sit amet, consectetur adipiscing'>
      
          <div className="row">
            <div className="col-md-12">
              <div className="custom-text-container">
                <input
                  type="text"
                  className=""
                  name="name"
                  value=""
                  onChange={e => this.setState({ name: e.target.value })}
                  autoComplete="off"
                  required
                />
                <label htmlFor="name" className=" label-name">
                  <span className="content-name">full name</span>
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="custom-text-container">
                <input
                  type="text"
                  className=""
                  name="name"
                  value=""
                  onChange={e => this.setState({ name: e.target.value })}
                  autoComplete="off"
                  required
                />
                <label htmlFor="name" className=" label-name">
                  <span className="content-name">email</span>
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="custom-text-container">
                <input
                  type="text"
                  className=""
                  name="name"
                  value=""
                  onChange={e => this.setState({ name: e.target.value })}
                  autoComplete="off"
                  required
                />
                <label htmlFor="name" className=" label-name">
                  <span className="content-name">contact number</span>
                </label>
              </div>
            </div>
          </div>

          <BottomRowStep skip={this.props.skip} next={this.props.next} />
      
      </Card>
    );
  }
}
