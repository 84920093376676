import React, { Component } from "react";

export default class BottomRowStep extends Component {
  render() {
    return (
      <div className="bottom-row-step">
        <button className='skip'    onClick={this.props.skip}>Skip for now</button>
        <button className='primary-button' onClick={this.props.next}>next step</button>
      </div>
    );
  }
}
