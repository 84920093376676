import React from "react";
import Nav from "../../components/navbar/nav";
import Card from "../../components/card/smallCard";
import SignUpComp from "../../components/auth/signUpComp";
export default function signIn() {
  return (
    <React.Fragment>
      <Nav />
      <section>
        <Card
          title="Login"
          subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit.  "
        >
          <SignUpComp />
        </Card>
      </section>
    </React.Fragment>
  );
}
