import React from "react";
import Nav from "../../components/navbar/nav";

import Testimonials from "../../components/home/testimonials";
import AboutUsCont from "../../components/pages/aboutUs";
import Footer from "../../components/navbar/footer";
export default function AboutUs() {
  return (
    <>
      <Nav />
      <AboutUsCont />
      <Testimonials />
      <Footer />
    </>
  );
}
