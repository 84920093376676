import React, { Component } from "react";

export default class contactUs extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="contact-us">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-5">
                <h1 className="title">contact us </h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Maecenas varius tortor nibh, sit amet tempor nibh finibus et.
                  Aenean eu enim justo. Vestibulum aliquam hendrerit molestie.
                  Mauris malesuada nisi sit amet augue accumsan tincidunt.
                </p>
                <div className="heading">
                  <div>Address</div> Quick passport, A252,Vashi plaza ,<br />{" "}
                  Sector 17, Vashi Navi Mumbai 400705 <br />
                  getquickpassport@gmail.com <br />
                  8652316581/02227654321
                </div>
              </div>
              <div className="col-md-5 contact-form">
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Full name"
                    class="form-control"
                    id="usr"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Email"
                    class="form-control"
                    id="usr"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Subject"
                    class="form-control"
                    id="usr"
                  />
                </div>
                <div class="form-group">
                  <textarea
                    class="form-control"
                    placeholder="Message"
                    rows="5"
                    id="comment"
                  ></textarea>
                  <button className="primary-button mt-4">submit</button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="contact-us-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.864276902809!2d72.99585031531304!3d19.06970405712489!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c14b94c972fd%3A0xb14ba1caa5b93403!2sQuick%20Passport!5e0!3m2!1sen!2sin!4v1580238772037!5m2!1sen!2sin"
            frameborder="0"
            style={{ border: 0, allowfullscreen: "" }}
          ></iframe>
        </section>
      </React.Fragment>
    );
  }
}
