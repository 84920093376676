import React, { Component } from "react";

import Nav from "../../components/navbar/nav";
import FreshPassportComp from "../../components/service_page/freshPassportComp";
import Wrapper from "../../components/wrapper/wrapper";

class freshPassport extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <Nav />
        <Wrapper
          heading="Fresh Passport"
          subheading="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        >
        <FreshPassportComp />
        </Wrapper>
      </React.Fragment>
    );
  }
}

export default freshPassport;
