import React from "react";
import SlickSlider from "react-slick";
var Person =
  "https://images.unsplash.com/photo-1500048993953-d23a436266cf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1949&q=80";
export default function AboutUs() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    className: "team-slider"
  };
  return (
    <div className="about-us">
      <section className="hero-about-us">
        <div className="container">
          <h1 className="title">About us</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
            varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim
            justo. Vestibulum aliquam hendrerit molestie. Mauris malesuada nisi
            sit amet augue accumsan tincidunt. Maecenas tincidunt, velit ac
            porttitor pulvinar, tortor eros facilisis libero, vitae commodo nunc
            quam et ligula. Ut nec ipsum sapien. Interdum et malesuada fames ac
            ante ipsum primis in faucibus. Integer id nisi nec nulla luctus
            lacinia non eu turpis. Etiam in ex imperdiet justo tincidunt
            egestas. Ut porttitor urna ac augue cursus tincidunt sit amet sed
            orci.
          </p>
        </div>
      </section>
      <section className="our-team">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <h2 className="title">our team</h2>
              <p></p>
            </div>
            <div className="col-md-8">
              <SlickSlider {...settings}>
                <div>
                  <div className="custom-card-team">
                    <img src={Person} />
                    <h3>Matthew Parsons</h3>
                    <p>Matthew Parsons</p>
                  </div>
                </div>
                <div>
                  <div className="custom-card-team">
                    <img src={Person} />
                    <h3>Matthew Parsons</h3>
                    <p>Matthew Parsons</p>
                  </div>
                </div>
                <div>
                  <div className="custom-card-team">
                    <img src={Person} />
                    <h3>Matthew Parsons</h3>
                    <p>Matthew Parsons</p>
                  </div>
                </div>
                <div>
                  <div className="custom-card-team">
                    <img src={Person} />
                    <h3>Matthew Parsons</h3>
                    <p>Matthew Parsons</p>
                  </div>
                </div>
              </SlickSlider>
            </div>
          </div>
        </div>
      </section>
      <section className='choose-us'>
        <div className="container">
            <h4 className='title'>
                why chosse us
            </h4>
            <p className='subtitle'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.  </p>
          <div className="row justify-content-around">
            <div className="col-md-3">
                <div className='panel-custom'>
                    <img src={Person} className='img-fluid'/>
                    <div className='bottom-block'>
                        <h4 className='heading'>
                        Customer Support
                        </h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius</p>
                    </div>
                </div>
            </div>
            <div className="col-md-3">
                <div className='panel-custom'>
                    <img src={Person} className='img-fluid'/>
                    <div className='bottom-block'>
                        <h4 className='heading'>
                        Customer Support
                        </h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius</p>
                    </div>
                </div>
            </div>
            <div className="col-md-3">
                <div className='panel-custom'>
                    <img src={Person} className='img-fluid'/>
                    <div className='bottom-block'>
                        <h4 className='heading'>
                        Customer Support
                        </h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius</p>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
