import React from "react";

import Illu from "../../images/group-2-copy.svg";

export default function OurProcess() {
  return (
    <section className=" our-process">
      <div className="container">
        {" "}
        <div className="row">
          <div className="col-md-6 order-md-1 order-2">
            <img
              src={Illu}
              className="img-fluid"
              alt="illusatrion for process"
            />
          </div>
          <div className="col-md-6 order-md-2 order-1">
            <h4>Our Process</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur elit. Maecenas varius
              tortor nibh, sit amet tempor
            </p>
            <ul>
              <li srno="1">Analyse and Preprare your file</li>
              <li srno="2">Verify your pre-requisites (Check-list)</li>
              <li srno="3">Register & Prepare Online Application</li>
              <li srno="4">Upload your documents</li>
              <li srno="5">Pay neccesary fees to process your Application</li>
              <li srno="6">
                Book an appointment the nearest Passport Seva kendra
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
