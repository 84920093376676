import React, { Component } from "react";

// API
import { signUp } from "../../services/auth";

export default class SignUpComp extends Component {
  createUser = () => {
    let dataString = {
      first_name: "ashish",
      last_name: "saiwal",
      email_id: "user@example.com",
      mobile_no: 8275556819,
      password: "ashish112233"
    };
    signUp(dataString)
      .then(res => console.log(res))
      .catch(error => {
        console.log(`Error => ${JSON.stringify(error.response)}`);
       
      });
  };
  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="custom-text-container">
            <input
              type="text"
              className=""
              name="name"
              autoComplete="off"
              required
            />
            <label htmlFor="name" className=" label-name">
              <span className="content-name">email</span>
            </label>
          </div>
        </div>
        <div className="col-md-12">
          <div className="custom-text-container">
            <input
              type="text"
              className=""
              name="name"
              autoComplete="off"
              required
            />
            <label htmlFor="name" className=" label-name">
              <span className="content-name">contact number</span>
            </label>
          </div>
        </div>
        <div className="col-md-12 text-center mt-4">
          <div className="info-text mt-4">Already signed up ?</div>
          <button className="primary-button m-3" onClick={this.createUser}>
            sign up
          </button>
        </div>
      </div>
    );
  }
}
