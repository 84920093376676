import axios from "axios";
import apiList from "../config/config";

let headers = { "Content-Type": "application/json" };

export const signUp = dataString => {
    alert(`${apiList.baseURL}${apiList.signUp}  -- ${JSON.stringify(dataString)}`)
  return axios.post(`${apiList.baseURL}${apiList.signUp}`, dataString, {
    headers: headers
  });
};
