import React from 'react'

export default function SmallCard(props) {
    return (
        <div className="custom-card-small">
        <h3>{props.title}</h3>
        <h4>{props.subtitle}</h4>
        {props.children}
      </div>
    )
}
