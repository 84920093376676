import React, { Component } from "react";

class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="custom-card">
        <h3>{this.props.title}</h3>
        <h4>{this.props.subtitle}</h4>
        {this.props.children}
      </div>
    );
  }
}

export default Card;
