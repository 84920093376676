import React, { Component } from "react";
import Card from "../card/card";
import BottomRowStep from "../common/bottomRowStep";

// Import React FilePond
import { FilePond, File, registerPlugin } from "react-filepond";

// import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export default class PanCardUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { files, setFiles } = this.state;
    return (
      <Card
        title="Pan card upload"
        subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing"
      >
        <div className="file-container">
          <div className="heading">Pan card upload*</div>

          <FilePond
            files={files}
            allowMultiple={false}
            onupdatefiles={setFiles}
            labelIdle='<div class="filepond--label-action upload-button">Browse</div>'
          />
          <div className="info-text text-center">
            Lorem ipsum dolor sit amet, consectetur adipiscing
          </div>
        </div>

        <BottomRowStep skip={this.props.skip} next={this.props.next} />
      </Card>
    );
  }
}
