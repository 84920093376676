import React from "react";
import Nav from "../../components/navbar/nav";
export default function TermsAndCondition() {
  return (
    <React.Fragment>
      <Nav />
      <section className="term-n-condition">
        <div className="container">
          <h1 className="title">Terms & Conditions</h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
          <div className="row">
            <div className="col-md-12">
              <div className="custom-card">
                <div className="reading-content">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Maecenas varius tortor nibh, sit amet tempor nibh finibus
                    et. Aenean eu enim justo. Vestibulum aliquam hendrerit
                    molestie. Mauris malesuada nisi sit amet augue accumsan
                    tincidunt. Maecenas tincidunt, velit ac porttitor pulvinar,
                    tortor eros facilisis libero, vitae commodo nunc quam et
                    ligula. Ut nec ipsum sapien. Interdum et malesuada fames ac
                    ante ipsum primis in faucibus. Integer id nisi nec nulla
                    luctus lacinia non eu turpis. Etiam in ex imperdiet justo
                    tincidunt egestas. Ut porttitor urna ac augue cursus
                    tincidunt sit amet sed orci.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Maecenas varius tortor nibh, sit amet tempor nibh finibus
                    et. Aenean eu enim justo. Vestibulum aliquam hendrerit
                    molestie. Mauris malesuada nisi sit amet augue accumsan
                    tincidunt. Maecenas tincidunt, velit ac porttitor pulvinar,
                    tortor eros facilisis libero, vitae commodo nunc quam et
                    ligula. Ut nec ipsum sapien. Interdum et malesuada fames ac
                    ante ipsum primis in faucibus. Integer id nisi nec nulla
                    luctus lacinia non eu turpis. Etiam in ex imperdiet justo
                    tincidunt egestas. Ut porttitor urna ac augue cursus
                    tincidunt sit amet sed orci.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Maecenas varius tortor nibh, sit amet tempor nibh finibus
                    et. Aenean eu enim justo. Vestibulum aliquam hendrerit
                    molestie. Mauris malesuada nisi sit amet augue accumsan
                    tincidunt. Maecenas tincidunt, velit ac porttitor pulvinar,
                    tortor eros facilisis libero, vitae commodo nunc quam et
                    ligula. Ut nec ipsum sapien. Interdum et malesuada fames ac
                    ante ipsum primis in faucibus. Integer id nisi nec nulla
                    luctus lacinia non eu turpis. Etiam in ex imperdiet justo
                    tincidunt egestas. Ut porttitor urna ac augue cursus
                    tincidunt sit amet sed orci.
                  </p>
                </div>

                <footer className="bottom-row-step">
                  <button className="skip">decline</button>
                  <button className="primary-button">accept</button>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
