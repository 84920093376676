import React, { Component } from 'react'

import Nav from '../../components/navbar/nav'
import Slider from '../../components/home/slider'
import ServiceCard from '../../components/home/serviceCard'
import OurProcess from '../../components/home/ourProcess'
import Testimonials from '../../components/home/testimonials'
import Faq from '../../components/home/faq'
import Footer from '../../components/navbar/footer'

export default class index extends Component {
    render() {
        return (
          <React.Fragment>
              <Nav/>
              <Slider/>
              <ServiceCard/>
              <OurProcess/>
              <Testimonials/>
              <Faq/>
              <Footer/>
          </React.Fragment>
        )
    }
}
